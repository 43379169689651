<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="11"></user-left>
        </div>
        <div class="col-10 f-14">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12">
              <h6>收藏资讯</h6>
            </div>
            <div class="col-12">
              <div class="clearfix mt-4 d-flex align-items-center">
                <div class="float-left">
                  更新时间：
                </div>
                <div class="float-left">
                  <b-form-input type="date" v-model="para.param.startTime"></b-form-input>
                </div>
                <div class="float-left ml-2 mr-2">
                 至
                </div>
                <div class="float-left">
                  <b-form-input type="date" v-model="endTime" @change="getList"></b-form-input>
                </div>
                <div class="float-left ml-3">
                  <a class="ml-3"   href="javascript:;" @click="createBeginDate(1)" :class="{'active':yues==1}" >近一月</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(3) " :class="{'active':yues==3}">近三月</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(6) " :class="{'active':yues==6}">近半年</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(12)" :class="{'active':yues==12}">近一年</a>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4 mb-4">
              <div class="card border-0">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-centered border mb-0">
                      <thead class="table-light">
                      <tr>
                        <th style="width: 20px">
                          <div class="mt-1">
                            <b-form-checkbox
                                    value="true"
                                    unchecked-value="false"
                                    v-model="qxSelect"
                                    @change="allChecked"
                            ></b-form-checkbox>
                          </div>
                        </th>
                        <th class="w-50">详情</th>
                        <th>更新时间</th>
                        <th style="width: 80px">操作</th>
                      </tr>
                      </thead>
                      <tbody v-if="collection.records && collection.records.length > 0">
                      <tr v-for="m in collection.records"  :key="m.id">
                        <td>
                          <b-form-checkbox
                                  unchecked-value="false"
                                  name="check"
                                  v-model="selected"
                                  :value="m.id"
                                  :checked="allSelect"
                          ></b-form-checkbox>
                        </td>
                        <td >
                          <h6>{{m.content.title}}</h6>
                          <div class="mt-2 text-gray-777 "   >
                            <span class="ml-2  text-truncate " v-html="$options.filters.ellipsis(m.content.content)"></span>
                          </div>

                        </td>
                        <td>
                          <div>{{m.createdAt}}</div>
                        </td>

                        <td>
                          <div class="mt-1 mb-1 f-16">
                            <a href="javascript:void(0);" class="ml-2" >
                              <b-icon icon="trash" @click="deleteCollection(m.id)" ></b-icon>
                            </a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                      <tbody v-else>
                      <tr>
                        <td colspan="4">
                          <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                            <div class="text-gray-777">没有找到收藏资讯</div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <b-button variant="outline-secondary"  @click="batchDeleteCollection"  size="sm">取消收藏</b-button>
                    </div>
                    <div v-if="collection && collection.total > collection.size" class="my-4 d-flex justify-content-center">
                      <b-pagination @change="onPageChange" :total-rows="collection.total"
                                    :per-page="collection.size"></b-pagination>
                    </div>
                    <div >

                    </div>
                  </div>
                </div>
                <!-- end card-body-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
    data() {
        return {
            para: {
                param: {
                    type: "2",
                    startTime: "",
                    endTime: "",
                },
                size: 10,
                current: 1
            },
            endTime: '',
            collection: [],
            selected: [],
            allSelect: true,
            qxSelect:"",
            collectionIdList:[],
            yues:''
        };
    },
    methods: {
        onPageChange(i) {
            this.para.current = i;
            this.getList();
        },
        //获取当前日期几个月内的数据
        createBeginDate(yues){
            this.yues = yues;
            var date = new Date();
            date.setMonth(date.getMonth() - yues);
            date.toLocaleDateString()
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d

            var dqdate = new Date();
            dqdate.toLocaleDateString()
            var b = dqdate.getFullYear()
            var e = dqdate.getMonth() + 1
            e = e < 10 ? ('0' + e) : e
            var q = dqdate.getDate()
            q = q < 10 ? ('0' + q) : q

            const dqtime = b + '-' + e + '-' + q;
            this.para.param.startTime=time;
            this.endTime=dqtime;
            this.getList();
        },
        getList() {
            if(!this.$test.empty(this.endTime)) {
                this.para.param.endTime = this.endTime + ' 23:59:59';
            }
            this.$http.post(this.$api.user.findPageCollection, this.para).then(res => {
                if (res.code == 1) {
                    this.collection = res.result;
                }
            });
        },
        deleteCollection(id){
            this.$http.delete(this.$api.user.deleteCollection+id).then(res=>{
                if(res.code==1){
                    this.$model.toast('删除成功!', 2000);
                    this.getList();
                }
            });
        },
        allChecked() {
            if (this.qxSelect=="true") {//实现正选
                this.selected = [];
                for (let m in this.Collection.records) {
                    this.selected.push(this.collection.records[m].id);
                }
            } else{
                this.selected = [];
            }
        },
        batchDeleteCollection() {
          if(this.selected.length > 0) {
            this.$http.delete(this.$api.user.batchDeleteCollection,this.selected).then(res => {
                if (res.code == 1) {
                    this.$model.toast('删除成功!', 2000);
                    this.selected = [];
                    this.getList();
                }
            });
          } 
        }
    },
    filters: {
        //当渲染的文字超出9字后显示省略号
        ellipsis(value) {
            value = value.replace(/<.*?>/ig,"")       //把v-html的格式标签替换掉
            if (value.length > 30) {
                return value.slice(0, 30) + "...";
            } else{
                return value
            }
        }
    },
    created(){
        this.getList();
    }
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
.active {
  background: #00cc00;
  padding: 5px;
  color: #FFFFFF;
  border-radius: 4px;
}
</style>
